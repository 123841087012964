














































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import { getStrArr } from "@/utils/base";
import {
  GetEyeList,
  PostSources,
  GetEyeCreator,
  GetEyeTags,
  GetEyePro,
} from "@/request/research";
import { GetFirst, GetEyeFilter, PostEyeExport } from "@/request/storehouse";
import TimeRange from "@/components/time-range.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TimeRange,
  },
})
export default class Name extends Vue {
  private filters: any = {
    表格名称: "",
    sources: [],
    user_ids: [],
    标签: [],
    标注状态: "",
    开始时间: "",
    结束时间: "",
    条件: "全部条件",
    行研究指标: [],
    列研究指标: [],
  };
  private sources: any = [];
  private creators: any = [];
  private labels: any[] = [];
  private firstOpt: any[] = [];
  /**
   * @description
   */
  private sourceChange(e: any) {
    this.filters.sources = e;
    // 重置创建人
    this.filters["创建人"] = [];
    // 重置创建人选项
    this.getCreatorsList();
  }
  private remoteSourceMethod(e: any) {
    this.fetchSource(e);
  }
  private remoteCreatorMethod(e: any) {
    this.getCreatorsList(e);
  }
  /**
   * @description 获得来源
   */
  private fetchSource(val?: any) {
    const params: any = {
      keywords: val || "",
    };
    PostSources(this, params).then((data: any) => {
      this.sources = data;
      this.getCreatorsList();
    });
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList(e?: any) {
    const params: any = {
      ids: [],
      keywords: e || "",
    };
    if (this.filters.sources && this.filters.sources.length > 0) {
      this.filters.sources.forEach((ele: any) => {
        params.ids.push(ele.source_id);
      });
    }
    GetEyeCreator(this, params).then((data: any) => {
      this.creators = data;
      if (this.filters.user_ids && this.filters.user_ids.length > 0) {
        this.filters.user_ids.forEach((ele: any) => {
          const index = this.creators.findIndex((val: any) => {
            return ele.create_id === val.create_id;
          });
          if (index == -1) {
            this.creators.unshift(ele);
          }
        });
      }
    });
  }
  /**
   * @description 获得可选标签列表
   */
  private remoteLabelMethod(e: any) {
    const params: any = {
      keywords: e || "",
    };
    GetEyeTags(this, params).then((data: any) => {
      this.labels = data;
    });
  }
  private timeChange(e: any) {
    this.filters = e;
  }
  /**
   * @description 添加研究指标变量
   */
  private addTiaojian() {
    this.filters["行研究指标"].push({ result: {}, selected: {} });
  }
  /**
   * @description 删除一个条件
   */
  private deleteTiaojian(index: any) {
    this.filters["行研究指标"].splice(index, 1);
  }
  /**
   * @description 获取当变量第一列可选项
   */
  private fetchFirst() {
    GetFirst(this).then((data: any) => {
      this.firstOpt = data;
    });
  }
  /**
   * @description 搜索模板下具体的字段
   */
  private searchItems(l: any) {
    const params: any = {
      first: l.first,
    };
    GetEyeFilter(this, params).then((data: any) => {
      l.items = data;
      this.$forceUpdate();
    });
  }
  /**
   * @description 导出
   */
  private startExport() {
    console.log(this.filters);
    if (!this.filters["表格名称"]) {
      this.$message.warning("表格名称不能为空");
      return;
    }
    if (this.filters["表格名称"].length > 30) {
      this.$message.warning("表格名称最多30个字");
      return;
    }
    // 处理删选条件
    let result: any = [];
    if (this.filters["行研究指标"].length > 0) {
      this.filters["行研究指标"].forEach((ele: any) => {
        const obj: any = {
          title: ele.selected.title,
          id: ele.selected.id,
          type: ele.selected.type,
          first: ele.selected.first,
          condition: ele.result.condition,
          value: ele.result.value,
        };
        result.push(obj);
      });
    }
    // 处理
    const params: any = {
      表格名称: this.filters["表格名称"],
      数据来源: getStrArr(this.filters["sources"], "source_id"),
      创建人: getStrArr(this.filters["创建人"], "creator_id"),
      标签: this.filters["标签"],
      标注状态: this.filters["标注状态"],
      开始时间: this.filters["开始时间"],
      结束时间: this.filters["结束时间"],
      筛选条件: result,
      条件: this.filters["条件"],
    };
    console.log(params);
    const loading = this.$loading({
      lock: true,
      text: "导出中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostEyeExport(this, params, loading).then((data: any) => {
      loading.close();
      this.$confirm(
        "数据导出中，您可切换或者关闭页面，导出进度和下载数据表格可至右上角导出中心。",
        "导出",
        {
          confirmButtonText: "跳转至下载中心查看",
          cancelButtonText: "返回目图标注",
          customClass: "commonConfirm",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      )
        .then(() => {
          this.$router.push("/main/export/export-result");
        })
        .catch((e: any) => {
          if (e == "cancel") {
            this.$router.back();
          }
        });
    });
  }
  private mounted() {
    this.fetchFirst();
  }
}
